<template>
  <v-card :disabled="store.dialogLoader">
    <v-toolbar color="primary" title="Conta" />
    <v-card-text>
      <v-expansion-panels v-model="panel" :disabled="store.dialogLoader">
        <v-expansion-panel title="Dados Pessoais" value="dadosPessoais">
          <v-expansion-panel-text class="mx-sm-10 ma-sm-10 ma-2 mx-1">
            <v-row v-if="userDetails">

              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  id="email"
                  v-model="store.userLogged.email"
                  class=""
                  color="primary"
                  prepend-icon="mdi-email-outline"
                  label="Email"
                  readonly
                  maxlength="150"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  id="cpf"
                  v-model="userDetails.cpf"
                  v-mask="'###.###.###-##'"
                  class=""
                  :clearable="store.userLogged.userDetails == null"
                  color="primary"
                  prepend-icon="mdi-card-text-outline"
                  label="CPF"
                  placeholder="cpf"
                  maxlength="14"
                  :readonly="userDetails.cpf != null"
                  :error-messages="v$.userDetails.cpf.$invalid ? ['Digite um CPF válido'] : []"
                  @click:clear="errorMessageMail = null"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  id="firstName"
                  v-model="store.userLogged.firstName"
                  class="mb-5"
                  color="primary"
                  prepend-icon="mdi-card-account-details-outline"
                  label="Primeiro Nome"
                  placeholder="Seu primeiro nome"
                  readonly
                  maxlength="25"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  id="lastName"
                  v-model="store.userLogged.lastName"
                  class=""
                  color="primary"
                  prepend-icon="mdi-card-account-details-outline"
                  label="Sobrenome"
                  placeholder="Sobrenome"
                  readonly
                  maxlength="25"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <v-text-field
                  id="nascimento"
                  v-model="userDetails.nascimento"
                  v-mask="'##/##/####'"
                  class=""
                  :clearable="store.userLogged.userDetails == null"
                  color="primary"
                  prepend-icon="mdi-baby-face-outline"
                  label="Nascimento"
                  placeholder="data de nascimento"
                  maxlength="10"
                  :readonly="userDetails.nascimento != null"
                  :error-messages="v$.userDetails.nascimento.$invalid ? ['Digite uma data válida'] : []"
                />
              </v-col>

              <!-- Avatar Upload -->
              <v-col cols="12" md="6" sm="12">
                <v-file-input
                  v-model="avatar"
                  accept="image/png, image/jpeg"
                  label="Foto de Perfil"
                  prepend-icon="mdi-camera"
                  @change="validateAvatar"
                ></v-file-input>
                <v-img
                  v-if="avatarPreview"
                  :src="avatarPreview"
                  class="mt-2"
                  max-height="200"
                ></v-img>
              </v-col>

               <!-- Galeria de Fotos Upload -->
              <v-col cols="12">
                <v-file-input
                  v-model="gallery"
                  accept="image/png, image/jpeg"
                  multiple
                  label="Galeria de Fotos"
                  prepend-icon="mdi-image-multiple"
                  @change="validateGallery"
                ></v-file-input>
                <v-row class="mt-2" v-if="galleryPreviews.length">
                  <v-col cols="6" sm="4" md="3" v-for="(src, index) in galleryPreviews" :key="index">
                    <v-img :src="src" max-height="100"></v-img>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Sobre Mim -->
              <v-col cols="12">
                <v-textarea
                  v-model="aboutMe"
                  label="Sobre Mim"
                  placeholder="Fale um pouco sobre você..."
                  rows="3"
                  counter="300"
                  maxlength="300"
                ></v-textarea>
              </v-col>
             
              <!-- Botão de Salvar -->
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :width="isSmallScreen ? '100%' : ''"
                  flat
                  variant="outlined"
                  @click="updateDetails"
                >
                  Salvar
                </v-btn>
              </v-col>

            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel
          title="Dados de Contato"
          value="dadosDeContato"
        >
          <v-expansion-panel-text class="mx-sm-10 ma-sm-10 ma-2 mx-1">
            <v-row v-if="userDetails">
              <v-col
                v-if="!store.userLogged.userDetails.telefones || store.userLogged.userDetails.telefones.length == 0"
                cols="12"
                class="text-center"
              >
                Você ainda não tem telefone de contato cadastrado.
              </v-col>

              <v-col
                v-if="store.userLogged.userDetails.telefones && store.userLogged.userDetails.telefones.length > 0"
                cols="12"
                class="text-center"
              >
                <v-list
                  max-width="300"
                  class=""
                >
                  <v-list-item
                    v-for="item in store.userLogged.userDetails.telefones"
                    :key="item.id"
                    :title="item.numero"
                    :subtitle="item.tipo"
                  >
                    <template #prepend>
                      <v-avatar color="grey-lighten-1">
                        <v-icon color="white">
                          mdi-cellphone-basic
                        </v-icon>
                      </v-avatar>
                    </template>

                    <template #append>
                      <v-btn
                        color="primary"
                        icon="mdi-trash-can"
                        variant="text"
                        @click="removerTelefone(item)"
                      />
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                v-if="store.userLogged.userDetails.telefones && store.userLogged.userDetails.telefones.length > 0"
                cols="12"
                class="text-center"
              >
                <v-divider />
              </v-col>
            </v-row>
              
            <v-expand-transition>
              <v-row v-if="userDetails && showAdicionarNovoTelefone">  
                <v-col
                  v-if="newTelefone"
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  class="pb-0"
                >
                  <v-checkbox
                    v-model="newTelefone.padrao"
                    label="Usar como padrão"
                  />
                </v-col>

                <v-col
                  v-if="newTelefone"
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  class="pt-0 pb-0"
                >
                  <v-select
                    v-model="newTelefone.tipo"
                    :items="tipoTelefoneList"
                    label="Tipo de Telefone"
                    :error-messages="v$.newTelefone.tipo.$invalid ? ['Selecione o tipo'] : []"
                    prepend-inner-icon="mdi-playlist-check"
                  />
                </v-col>

                <v-col
                  v-if="newTelefone"
                  cols="12"
                  lg="4"
                  md="4"
                  sm="12"
                  class="pt-0 pb-0"
                >
                  <v-text-field
                    id="telefone"
                    v-model="newTelefone.numero"
                    v-mask="'(##) ##### ####'"
                    class=""
                    color="primary"
                    prepend-inner-icon="mdi-cellphone-basic"
                    label="Número"
                    placeholder="Telefone"
                    maxlength="15"
                    append-icon="mdi-plus"
                    :disabled="v$.newTelefone.tipo.$invalid"
                    :error-messages="v$.newTelefone.numero.$invalid && newTelefone.numero 
                      && newTelefone.numero.length > 10 ? ['Digite um telefone válido'] : []"
                    @click:append="addTelefone()"
                  />
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-expand-transition>
              <v-row v-if="userDetails && !showAdicionarNovoTelefone">  
                <v-col
                  v-if="newTelefone"
                  cols="12"
                >
                  <v-btn
                    color="primary"
                    :width="isSmallScreen ? '100%': ''"
                    flat 
                    variant="outlined"
                    @click="showAdicionarNovoTelefone = true"
                  >
                    Adicionar novo Telefone
                  </v-btn>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions class="pa-5">
      <!-- <v-spacer></v-spacer>
      <v-btn color="" prepend-icon="mdi-cloud-upload" class="px-4" rounded="lg" large :style="btnCardStyle" elevation="2">
        Click here</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import UserDetails from '@/components/entity/UserDetails';
import Telefone from '@/components/entity/Telefone';

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import moment from 'moment'

export default {

  mixins: [
    myMixin,
  ],

  setup () {
    return { v$: useVuelidate() }
  },

  data: () => ({

    panel : [],

    userDetails : null,
    
    errorMessage : null,
    store,
    isSmallScreen : false,
    newTelefone : null,
    tipoTelefoneList : ['Pessoal', 'Comercial'],
    showAdicionarNovoTelefone : false,
    avatar: null,
    gallery: [],
    aboutMe: '',
    avatarPreview: null,
    galleryPreviews: [],

  }),

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  mounted() {

    this.isSmallScreen = this.$vuetify.display.mobile;

    this.simpleServiceCall();
    if(!store.userLogged.userDetails){
      this.userDetails = new UserDetails();
    }else{
      this.userDetails = store.userLogged.userDetails;
    }

    this.newTelefone = new Telefone();
    this.newTelefone.tipo = this.tipoTelefoneList[0];

  },

  methods: {

    removerTelefone(myItem){

      console.log('Removendo telefone');

      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/user/telefone/remove",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: myItem
      }).then(response => {
        //console.log('user logged : \n' + JSON.stringify(response.data));
        console.log('salvou telefone: ' + response.data.id);
        store.userLogged.userDetails = response.data;
        store.dialogLoader = false;
        store.globalSnackbarMessage = "Telefone Removido"
        store.globalSnackbar = true;
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.showDialogError = true;
      });

    },

    addTelefone(){
      
      if(!this.v$.newTelefone.numero.$invalid){
        //console.log('Adicionando telefone');

        store.dialogLoader = true;
        this.axios({
          method: "post",
          url: services.serviceHost + "/user/telefone/add",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          data: this.newTelefone
        }).then(response => {
          //console.log('user logged : \n' + JSON.stringify(response.data));
          console.log('salvou telefone: ' + response.data.id);
          store.userLogged.userDetails = response.data;
          store.dialogLoader = false;
          store.globalSnackbarMessage = "Telefone Salvo"
          store.globalSnackbar = true;
          this.showAdicionarNovoTelefone = false;
          this.newTelefone = new Telefone();
        })
        .catch(error => {
          console.log(error.message);
          store.dialogLoader = false;
          store.showDialogError = true;
        });
      }
    },

    updateDetails(){

      store.dialogLoader = true;
      this.userDetails.userId = store.userLogged.id
      this.axios({
        method: "post",
        url: services.serviceHost + "/user/details/update",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: this.userDetails
      }).then(response => {
        //console.log('user logged : \n' + JSON.stringify(response.data));
        console.log('salvou item: ' + response.data.id);
        store.userLogged = response.data;
        store.dialogLoader = false;
        store.globalSnackbarMessage = "Dados atualizados"
        store.globalSnackbar = true;
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.showDialogError = true;
        this.logout();
      });
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations (){

    return {

      newTelefone : {
        numero : {
          required,
          min: minLength(15),
          max: maxLength(15),
        },
        tipo : {
          required,
        }
      },
      validateAvatar() {
      if (this.avatar && this.avatar.size > 2000000) {
        this.avatar = null;
        this.avatarPreview = null;
        alert('O avatar deve ser menor que 2 MB.');
      } else {
        this.avatarPreview = URL.createObjectURL(this.avatar);
      }
    },
    
    validateGallery() {
      this.galleryPreviews = this.gallery
        .filter((file) => file.size <= 2000000)
        .map((file) => URL.createObjectURL(file));
      if (this.gallery.some((file) => file.size > 2000000)) {
        alert('Cada foto deve ser menor que 2 MB.');
        this.gallery = this.gallery.filter((file) => file.size <= 2000000);
      }
    },

      userDetails: {
        nascimento: {
          required,
          min: minLength(10),
          max: maxLength(10),
          minValue : value => {
            if(value && value.length == 10){
              let validDate = moment(value, "DD/MM/YYYY");
              if(validDate.isValid()){
                let hoje = moment();
                let myDiff = hoje.diff(validDate, 'years');
                if(myDiff >= 18 && myDiff <= 90){
                  return true;
                }else{
                  return false;
                }
              }else{
                return false;
              }
            }
          }
        },
        cpf: {
          required,
          min: minLength(14),
          max: maxLength(14),
          minValue : value => {
            if(value != null && value.length == 14){
              return this.testaCPF(value.replaceAll('.', '').replace('-', ''));
            }
          }
        },
      }
    }
  }

}

</script>

<style scoped>


</style>
